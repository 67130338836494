import axios from 'axios'

const useCoupon = () => {
  const parkingApiHost = process.env.VUE_APP_API_HOST
  const exchangeParkingCouponUrl = `${parkingApiHost}/cars/NubiWebApi/api/Nubi/GetCouponNumber`
  const signUrl = `${parkingApiHost}/cars/NubiWebApi/api/Nubi/SignIn`
  // const exchangeParkingCouponUrl = `/api/cars/NubiWebApi/api/Nubi/GetCouponNumber`
  // const signUrl = `/api/cars/NubiWebApi/api/Nubi/SignIn`

  const getAccessToken = async () => {
    const info = await getClientInformation()
    const response = await axios.post(signUrl, info)
    return response.data.accessToken
  }

  const exchange = async (data) => {
    const token = await getAccessToken()
    return axios.post(exchangeParkingCouponUrl, {
      ...data,
      accessToken: token
    })
  }

  const getClientInformation = async () => {
    // TODO: 3991 Use env ?
    return {
      "clientId": "bnViaQ==",
      "clientSecret": "UWNTNlRVa2pWWGlaWlIwV3JGYlJRR0I5THBSRm02Wk5MUklCL0RvbFkxWT0yMDI0MDkxMDA5MjIzMw=="
    }
  }

  return {
    exchange
  }
}

export default useCoupon