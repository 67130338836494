<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">折抵券詳情</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding mt-4" v-if="!isFetching">
        <div class="record p-4 max-w-md mx-auto bg-white rounded-lg mb-5 shadow-md mt-74 text-center">
          <img src="@/assets/icon/coupon.svg" alt="">
          <div class="fL" style="margin-bottom: 8px">折抵券序號</div>
          <div class="fL" style="margin-bottom: 16px">{{ coupon.couponNumber }}</div>
          <div class="fS" style="margin-bottom: 16px">使用期限：暫無使用期限</div>
          <div class="fS copy-text" @click="copyText(coupon.couponNumber)">複製序號</div>
        </div>

        <div class="buttons">
          <button @click="useThisCoupon">確定使用</button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "ParkingCouponsDetail",
  data() {
    return {
      isFetching: true,
      coupon: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    // console.log("mounted!");
    this.initCoupon()
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    // setTimeout(() => {
    //   console.log("Notifications", this.notifications);
    // }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    async initCoupon() {
      try {
        this.isFetching = true;
         const couponData = await this.$store.dispatch('coupon/fetchUserCoupon', this.$route.params.id);
         console.log('couponData', couponData);
         this.coupon = couponData;
      } catch (error) {
        this.goBack()
      } finally {
        this.isFetching = false;
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$notify({
          group: 'normal',
          type: 'success',
          title: '複製成功',
          duration: 3000,
        })
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    useThisCoupon() {
      this.$store.dispatch('coupon/updateUserCoupon', {
        ...this.coupon,
        isUsed: true,
      })
      this.$notify({
        group: 'normal',
        type: 'success',
        title: '已使用優惠券',
        duration: 3000,
      })
      this.goBack()
    },
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    submit(item) {
      console.log(item);
      // this.$router.push(`/parking-coupons/confirm/${item.id}`);
      // let collection = db.collection(
      //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      // );
      // let document = collection.doc(docId);
      // return db.runTransaction((transaction) => {
      //     return transaction.get(document).then((doc) => {
      //         transaction.update(document, {
      //             unread: false
      //         });
      //         return doc;
      //     });
      // });
      //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
      //   let config = {
      //     url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
      //     method: "POST",
      //     data: {
      //       topicId: this.user.user.firestoreChannelId,
      //       messageId: docId,
      //     },
      //   };
      //   return this.$http(config);
    },
  },
};
</script>

<style lang="scss" scoped>

button {
  color: white;
  height: 51px !important;
}

.records {
  width: 85% !important;
  margin: 48px auto;
}

.record {
  padding: 48px 0 32px 0 !important;
  margin-bottom: 32px !important;
}

.mt-74 {
  margin-top: 74px;
}

</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
